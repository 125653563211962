import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import registerServiceWorker from './registerServiceWorker'
import history from './Util/history'
import App from './Components/app'
import 'semantic-ui-css/semantic.min.css'
ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
)
registerServiceWorker()

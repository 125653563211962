import React, { Component } from "react";

class Hardware extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        Hårdvara: Jag har jobbat mycket med{" "}
        <a href="https://www.raspberrypi.org/" target="_new">
          Raspberry Pi
        </a>{" "}
        senaste åren i lite olika konfigurationer :
        <ul>
          <li>
            Mediacenter <br />
            Jag har kört{" "}
            <a href="https://osmc.tv/" target="_new">
              OSMC
            </a>{" "}
            den är enkel att få igång och har mycket konfigurerat - en Raspberry klarar nog inte att
            driva en hårddisk via USB men om kan kopplar in en{" "}
            <a
              href="https://www.discatonce.se/se/usb-hubbar/usb-20-hubb/d-link-usb-20-hubb-7-portar-med-natadapter.php"
              target="_new"
            >
              {" "}
              "powered" usb-hub
            </a>{" "}
            så kan man hänga på några diskar som man kan spara filmer / tv-serier på
          </li>
          <li>
            IOT<br />
          </li>
        </ul>
      </div>
    );
  }
}
export default Hardware;

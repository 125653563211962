import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import suki from '../../../Images/gsx600f.jpg'
import dekalhonda from '../../../Images/dekalhonda.jpg'
import renhonda from '../../../Images/renhonda.jpg'

class CarsMc extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Vadå femtioårskris ?.</h3>
        <p>
          Jag var femtiotvå när jag skaffade mc igen efter nästan trettio år.
          <br />
        </p>
        Om man var i min ålder och skaffade en rimlig båge så kostade försäkringen nästan inget!
        <br />
        Så jag hittade en Suzuki GSX 600 F och började köra. <br />
        <Image src={suki} width='400px' alt='Generic placeholder image' />
        Jag kunde snart konstatera att motorkaraktären inte riktigt var vad jag ville ha. Lite allt eller inget, inget
        under 9000 rpm och allt över 10000 - blev lite hetsigare körning än vad jag gillar - sedan är det något med
        tyngdpunkten på Suzuki - hur har dom fått upp den i tanken ?
        <br />
        så jag var och tittade på en V-twin, Suzuki det också , och tänkte att jag slänger väl ut min båge på Blocket så
        får vi se. och en månad senare var den såld.
        <h4>Så vad skall vi åka nu ?</h4>
        <Image src={dekalhonda} width='400px' alt='Generic placeholder image' />
        några veckor senare dök det upp en Honda VTR1000F Firestorm på blocket. - åkte och kollade och provkörde och
        tyckte att bågen behövde komma ut lite mer - husse hade tre små barn och nyköpt hus, Cykeln hade stått några år
        - i en lagård av lukten att döma.
        <br />
        Jag konstaterade när jag kört lite grand och kollat på nätet, att den cykeln hade ett typfel - kamkedjespännaren
        kunde ge upp helt plötsligt och utan förvarning - enda fixen var att byta dom typ var 2000 mil eller montera
        manuella spännare, min båge hade gått 2500 mil och dessutom stått länge ( problemet med spännarna är dessutom
        mera rost än slitage ) , så det blev inte så många mil den sommaren,
        <h4>vintern efter</h4>
        <Image src={renhonda} width='400px' alt='Generic placeholder image' />
        så plockade jag ner tanken och toppkåporna och gav mig på att dels byta spännarna och kolla ventilspelet, det
        var i slutändan en ganska smärtfri operation.
        <br />
        Motorcykeln var dessutom full av klistermärken och hade en magkåpa under motorn, Det var inget kul att tvätta en
        motorcykel och försöka på puts på klistermärken...
        <br />
        När jag plockade bort magkåpan så satt rören till oljekylen därunder. - kromade, men med gravrost. Det finns en
        MC-skrot utanför Trollhättan - som hade snygga rör för 400:- Så då åkte magkåpan och klistermärkena av också.
        <br />
        Nu återstår att fixa lacken på några av motorkåporna som suttit under magkåpan och lacka om gaffelbenen - dom
        har blivit ganska blästrade på framsidan Fast vi får se - har blivit lite sugen på att kanske sälja bågen och
        skaffa en Saab Cab istället ett tag
        <br />
        Kanske en billigare båge i så fall <br />
        - kanske något supermotard aktigt :-) SR600 ? XT600 ?<br />- eller en gammal BMW kanske ?
      </div>
    )
  }
}
export default CarsMc

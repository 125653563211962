import React, { Component } from 'react'
import imageSettingStore from '../../../Stores/image-settings-store'

class OldRaspberry extends Component {
  render() {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Senaste sex timmarna</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-UsedCores-RaspBerry.png')}

        <br />
        <h3>Senaste dygnet</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-UsedCores-RaspBerry.png')}

        <br />
        <h3>Senaste två dygnen</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-UsedCores-RaspBerry.png')}
        <br />

        <h3>Senaste veckan</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-UsedCores-RaspBerry.png')}
        <br />
        <h3>Senaste månaden</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-UsedCores-RaspBerry.png')}

        <br />
        <h3>Senaste året</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-UsedCores-RaspBerry.png')}
        <br />

        <h3>Allt uppmätt</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-UsedDisk-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-CpuTemp-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-CpuLoad-RaspBerry.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-UsedCores-RaspBerry.png')}
      </div>
    )
  }
}
export default OldRaspberry

import React, { Component } from "react";
import { List } from "semantic-ui-react";

class MediaPrograms extends Component {
  render() {
    return (
      <List.Item>
        <List.Icon name="tv" />
        <List.Content>
          <List.Header> Multi media apps </List.Header> <List.Description> </List.Description>{" "}
          <List.List>
            <List.Item>
              <List.Icon name="film" />
              <List.Content>
                <List.Header> VLC media player </List.Header>{" "}
                <List.Description>
                  VideoLan VLC är en bra video viewer - <br />
                  kan också hantera enklare konverteringar mellan format <br />
                  Vill du ha bättre, gratis konverterare så kolla på{" "}
                  <a href="https://handbrake.fr/"> HandBrake </a> <br />
                  <a href="https://www.videolan.org/vlc/index.html"> Ladda ner VLC </a>{" "}
                </List.Description>{" "}
              </List.Content>{" "}
            </List.Item>{" "}
            <List.Item>
              <List.Icon name="photo" />
              <List.Content>
                <List.Header> IrfanView </List.Header>{" "}
                <List.Description>
                  Fritt program för att visa allehanda bilder i olika format - <br />
                  Glöm inte ladda ner plugins <br />
                  <a href="https://www.irfanview.com/"> Ladda ner irfanview </a>{" "}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="edit" />
              <List.Content>
                <List.Header> G.I.M.P </List.Header>{" "}
                <List.Description>
                  Troligen det mest kapabla bildredigeringsprogrammet som finns ö.h.t. <br />
                  Gratis!-men jag säger inte att det är lätt att använda - <br />
                  finns för alla platformar också <br />
                  <a href="https://www.gimp.org/"> GIMP hemsida </a>{" "}
                </List.Description>
              </List.Content>
            </List.Item>
          </List.List>
        </List.Content>
      </List.Item>
    );
  }
}
export default MediaPrograms;

import React, { Component } from "react";

class CarsSaab extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        Saab - är en kul bil: <br />
        Jag fick tag i en 9-5 kombi -01 för snart fem år sedan som behövde lite omvårdnad.
        <br />
        Den fick en ny turbo och blockpackning och dessutom nya ram/vevlager.
        <br />
        när den behövde nya bromsrör bak och jag inte riktigt hade tid att fixa det så funderade jag
        på att skaffa en annan och ha den första som reservdelsbil, det slutade med att jag skaffade
        en till 9-5 combi -02 <br />
        - och har fortfarande inte bestämt vilken av dom som skall vara reservdelsbil.
        <br />
        02an hade lite sliten inredning och rostiga dörrar - så när jag fick tag i dörrar och
        skinnstolar så blev den uppfräshad.
        <br />
        01an har fått nya dörrar.
        <br />
        <br />
        Jag har bytt mjukvara i båda två - lagt i ett BioPower program från 07 så nu går båda
        bilarna på E85.
        <br />
        Dom drar ca 20% mer men bensin är ju 50% dyrare
        <br />
        Faktiska siffor finns här :{" "}
        <a href="https://www.spritmonitor.de/en/detail/624255.html" target="_new">
          Svart 01a
        </a>{" "}
        {" , "}
        <a href="https://www.spritmonitor.de/en/detail/787990.html" target="_new">
          Blå 02a
        </a>
        <br /> <br />
        <br />
        för att byta mjukvara i en Saab 9-5 -98 -10 så behöver man ;
        <ul>
          <li>
            Mjukvara för att läsa skriva ECU{" "}
            <a href="https://txsuite.org/download/trionic-can-flasher/" target="_new">
              Trionic CAN Flasher
            </a>
          </li>
          <li>
            Mjukvara för ändra i ECU{" "}
            <a href="https://txsuite.org/download/t7suite/" target="_new">
              Trionic T7 Suite
            </a>
            <br />
            annan orignal mjukvara finns i paketet T7Extras
          </li>
          <li>
            <a
              href="https://www.lawicel-shop.se/catalog/product/view/id/5781/s/lawicel-canusb-adapter-1m-usb-cable/category/59/"
              target="_new"
            >
              Usb CAN adapter
            </a>
            <br />
            <a
              href="https://www.lawicel-shop.se/catalog/product/view/id/5783/s/db9-to-obd2-can-for-canusb/category/59/"
              target="_new"
            >
              ODBII adapter
            </a>
          </li>
          <li>
            man kan också använda en billigare{" "}
            <a
              href="https://cdon.se/accessoarer-och-vaskor/elm327-elm-327-obd2-usb-bildiagnostik-felkodslasare-p38616376"
              target="_new"
            >
              ELM 327 adapter
            </a>
            - men det måste vara en som kopplas via USB
          </li>
        </ul>
        Sedan kan man välja på att programmera ECU i bilen eller skaffa en hanske (lämpligtvis från
        en skrotad bil med samma ECU som du har in din) det finns i praktiken tre oilka Saab system
        att välja på T5 , T7 och T8 se{" "}
        <a href="https://sv.wikipedia.org/wiki/Trionic" target="_new">
          wikipedia
        </a>{" "}
        Jag kör med en hanske från en skrotad bil - så kan man sitta i lugn och ro.
      </div>
    );
  }
}
export default CarsSaab;

import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import CarsGeneral from './Subs/cars-general'
import CarsMiniCooper from './Subs/cars-mini-cooper'
import CarsSayings from './Subs/cars-sayings'
import CarsSaab from './Subs/cars-saab'
import CarsVolvo from './Subs/cars-volvo'
import CarsMc from './Subs/cars-mc'
import { Menu } from 'semantic-ui-react'

class CarsMain extends Component {
  render () {
    return (
      <div>
        <Menu stackable attached='top' compact text='true'>
          <Menu.Item header href='/cars/'>
            Bilar / MC
          </Menu.Item>
          <Menu.Item icon='arrow right' />
          <Menu.Item href='/cars/mc'>Mc</Menu.Item>
          <Menu.Item href='/cars/saab'>Saab</Menu.Item>
          <Menu.Item href='/cars/minicooper'>Mini Cooper</Menu.Item>
          <Menu.Item href='/cars/volvo'>Volvo</Menu.Item>
          <Menu.Item href='/cars/sayings'>Bra Verktyg</Menu.Item>
        </Menu>
        <main>
          <Switch>
            <Route exact path='/cars/mc/' component={CarsMc} />
            <Route exact path='/cars/saab/' component={CarsSaab} />
            <Route exact path='/cars/volvo/' component={CarsVolvo} />
            <Route exact path='/cars/minicooper/' component={CarsMiniCooper} />
            <Route exact path='/cars/sayings/' component={CarsSayings} />
            <Route component={CarsGeneral} />
          </Switch>
        </main>
      </div>
    )
  }
}
export default CarsMain

import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        <p>
          Websidan och domänen ligger på{" "}
          <a HREF="http://www.one.com" TARGET="_new">
            {" "}
            one.com
          </a>
        </p>
        <p>
          om du vill skicka ett <a HREF="mailto:per-eric.larsson@densomlever.se">mail:</a>
        </p>
      </div>
    );
  }
}
export default About;

import React, { Component } from "react";
import { List } from "semantic-ui-react";

class DevPrograms extends Component {
  render() {
    return (
      <List.Item>
        <List.Icon name="code" />
        <List.Content>
          <List.Header> Development Tools </List.Header>{" "}
          <List.Description>
            <List.List>
              <List.Item>
                <List.Icon name="download" />
                <List.Content>
                  <List.Header> Wix(Windows Installer Xml) </List.Header>{" "}
                  <List.Description>
                    Verktyg för att generera installationsprogram(.msi) från en xml - fil <br />
                    <a href="https://wixtoolset.org/releases/"> wixtoolset.org </a>{" "}
                  </List.Description>{" "}
                </List.Content>{" "}
              </List.Item>{" "}
              <List.Item>
                <List.Icon name="magic" />
                <List.Content>
                  <List.Header>Visual Studio Code </List.Header>{" "}
                  <List.Description>
                    Microsoft 's fria kodeditor <br />{" "}
                    <a href="https://code.visualstudio.com/"> code.visualstudio.com </a>{" "}
                  </List.Description>{" "}
                </List.Content>{" "}
              </List.Item>{" "}
              <List.Item>
                <List.Icon name="node" />
                <List.Content>
                  <List.Header>Node.js</List.Header>{" "}
                  <List.Description>
                    JavaScript in the wild - behövs om man vill jobba med websidor- oftast
                    <br /> <a href="https://nodejs.org/en/">nodejs.org</a>{" "}
                  </List.Description>{" "}
                </List.Content>{" "}
              </List.Item>{" "}
              <List.Item>
                <List.Icon name="magic" />
                <List.Content>
                  <List.Header> First Object XML Editor </List.Header>{" "}
                  <List.Description>
                    En liten okänd editor för xml, inte så mycket funktioner vad gäller scheman osv{" "}
                    <br />
                    -men otroligt snabb på att ladda stora filer <br />
                    <a href="http://www.firstobject.com/dn_editor.htm">
                      {" "}
                      First Object 's hemsida
                    </a>{" "}
                  </List.Description>{" "}
                </List.Content>{" "}
              </List.Item>{" "}
            </List.List>{" "}
          </List.Description>{" "}
        </List.Content>{" "}
      </List.Item>
    );
  }
}
export default DevPrograms;

import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { Route, Switch } from 'react-router'
import ComputersGeneral from './computers-general'
import OwnPrograms from './Own/own-programs'
import GoodPrograms from './Good/good-programs'
import Hardware from './Hardware/hardware'
import HardwareShop from './Hardware/hardware-shop'

class ComputersMain extends Component {
  render() {
    return (
      <div>
        <Menu stackable attached='top' compact text >
          <Menu.Item header href='/computers/'>
            Datorer
          </Menu.Item>
          <Menu.Item icon='arrow right' />
          <Menu.Item href='/computers/ownprograms'>Egna Program</Menu.Item>
          <Menu.Item href='/computers/goodprograms'>Bra Program</Menu.Item>
          <Menu.Item href='/computers/hardware'>Hårdvara</Menu.Item>
          <Menu.Item href='/computers/hardware/shop'>Hårdvara Shopping</Menu.Item>
        </Menu>
        <main>
          <Switch>
            <Route path='/computers/ownprograms' component={OwnPrograms} />
            <Route path='/computers/goodprograms' component={GoodPrograms} />
            <Route path='/computers/hardware/shop' component={HardwareShop} />
            <Route path='/computers/hardware' component={Hardware} />
            <Route component={ComputersGeneral} />
          </Switch>
        </main>{' '}
      </div>
    )
  }
}
export default ComputersMain

import React, { Component } from "react";

class GeneralMain extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        GeneralMain
      </div>
    );
  }
}
export default GeneralMain;

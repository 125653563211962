import React, { Component } from "react";
import { List, Container } from "semantic-ui-react";
import GenericPrograms from "./generic-programs";
import ComPrograms from "./com-programs";
import MediaPrograms from "./media-programs";
import DevPrograms from "./dev-programs";

class GoodPrograms extends Component {
  render() {
    return (
      <Container height="90%">
        <div>
          Bra (free) programs !! <br />
          Jag använder sidan som referens för att snabbt få fart på en ny eller ominstallerad dator.
          <br />
        </div>
        <List>
          <GenericPrograms />
          <ComPrograms />
          <DevPrograms />
          <MediaPrograms />
        </List>
      </Container>
    );
  }
}
export default GoodPrograms;

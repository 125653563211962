import React, { Component } from 'react'

class NewRaspberry extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        NewRaspberry
      </div>
    )
  }
}
export default NewRaspberry

import React, { Component } from "react";
import { List } from "semantic-ui-react";

class ComPrograms extends Component {
  render() {
    return (
      <List.Item>
        <List.Icon name="paper plane" />
        <List.Content>
          <List.Header>Communication/Versioning Tools</List.Header>
          <List.Description>
            <List.List>
              <List.Item>
                <List.Icon name="git" />
                <List.Content>
                  <List.Header>Git</List.Header>
                  <List.Description>
                    Behöver nog ingen närmare förklaring <br />
                    <a href="https://git-scm.com/downloads">Git downloads</a>{" "}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="upload" />
                <List.Content>
                  <List.Header>WinSCP</List.Header>
                  <List.Description>
                    Bra program för att hantera filer på en unix/linux maskin - även ftp:a filer
                    till webserver på nätet
                    <br /> - verkar vara skrivet i Delphi dessutom - det jobbade jag i förra seklet
                    :-)
                    <br />
                    <a href="https://winscp.net/eng/index.php">Hämta WinSCP</a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="terminal" />
                <List.Content>
                  <List.Header>Putty</List.Header>
                  <List.Description>
                    generellt terminal program för att logga in på Raspberry <br />
                    - också bra på att hantera och skapa certifikat för SSL
                    <br />
                    - dessutom fungerar den hyfsat (slött) för att ssl-tunnla genom gateways
                    <br />
                    <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">
                      Putty kompilerad för windows
                    </a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="browser" />
                <List.Content>
                  <List.Header>Chrome</List.Header>
                  <List.Description>
                    Googles browser - Google tjänar pengar på vad du gör på nätet.
                    <br />
                    <a href="https://www.google.com/chrome/">Hämta chrome</a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="browser" />
                <List.Content>
                  <List.Header>Firefox</List.Header>
                  <List.Description>
                    Min favorit-browser <br />
                    - drivs av en stiftelse utan ekonomisk vinning
                    <br />
                    <a href="https://www.mozilla.org/en-US/firefox/new/">Hämta FireFox</a>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List.List>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
}
export default ComPrograms;

import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router'
import { observer } from 'mobx-react'
import { Menu, Dropdown } from 'semantic-ui-react'
import OldNetwork from './old/old-network'
import imageSettingsStore from '../../Stores/image-settings-store'

class Network extends Component {
  handleItemClick = (e, { name }) => {
    imageSettingsStore.setPictureSize(name)
  }

  render() {
    return (
      <div>
        <main>
          <Menu stackable attached='top' compact text>
            <Menu.Item header>Nätverk</Menu.Item>
            <Menu.Item icon='arrow right' />
            <Dropdown item text={'Images : ' + imageSettingsStore.PictureSize}>
              <Dropdown.Menu>
                <Dropdown.Item text='Small' name='Small' onClick={this.handleItemClick} />
                <Dropdown.Item text='Medium' name='Medium' onClick={this.handleItemClick} />
                <Dropdown.Item text='Large' name='Large' onClick={this.handleItemClick} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu>

          <Switch>
            <Route path='/monitor/net/old' component={OldNetwork} />
            <Route component={OldNetwork} />
          </Switch>
        </main>
      </div>
    )
  }
}
export default withRouter(observer(Network)) 

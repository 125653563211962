import React, { Component } from 'react'
import { observer } from 'mobx-react'
import programStore from '../../../Stores/program-store';

class OwnWindows extends Component {
  componentDidMount() {
    programStore.getprogramlist();
  }


  renderproginfo(pgmname) {
    if (programStore.Loading) {
      return (<div>
        <p>{pgmname}</p>
        Loading info !
      </div>)
    }
    var proginfo = programStore.getprogram(pgmname)
    if (!proginfo) {
      return (<div>
        <p>{pgmname}</p>
        UNDEFINED !!!
      </div>)

    }
    return (
      <div>
        <p>{pgmname}</p>
        latest version is :  {proginfo.ProductVersion} <br />
        released at : {proginfo.WixTime}<br />
        download from - <a href={programStore.geturl(proginfo.MsiFileName)} >{proginfo.MsiFileName}</a>
      </div >
    )
  }

  render() {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Egna Windows program</h3>
        <p>
          {this.renderproginfo("TurboTray")}

          <br />
          Ett litet program som jag tog fram för att det inte fanns något program som gjorde vad
          programmet gör:
          <ul>
            <li>
              kopiera information till klippbordet så man enkelt kan klistra in den varsomhelst;<br />
              <ul>
                <li>Datum, Datorns namn, Inloggad användare,Datorns ip, Datorns mac-adress</li>
                <li>Slumptal, GUID</li>
                <li>Egna strängar</li>
              </ul>

            </li>
            <li>Tvätta klippbordet: ta bort alla format utom ren text </li>
            <li>
              Kopiera, sortera och döpa om bildfiler m.h.a. Exif information <br />
              - fungerar med .jpg, .nef filer iallafall{' '}
            </li>
            <li>
              programmet använder ClickOnce så det håller sig självt uppdaterat <br />
              - främst vid uppstart men man kan kolla om det finns en nyare version när som helst från programmet
            </li>
          </ul>
        </p>
      </div>
    )
  }
}
export default observer(OwnWindows)

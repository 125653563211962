import React, { Component } from "react";

class HardwareShop extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        <h3>Hårdvara shopping: </h3>
        <p> här kommer några affärer som jag brukar handla hos </p>
        <ul>
          <li><a href="https://www.discatonce.se/se/" target="_new">DiscAtOnce</a><br />
            väldigt bra priser på kablar och kontakter - levererar snabbt <br />
            - typiskt halva priset mot Kjell&amp;Co          </li>


          <li>
            <a href="https://www.netonnet.se">NetOnNet</a> <br />svårt att undvika som Boråsare :-)<br />
            Har hyfsade priser på mycket - oftast ingen idé att beställa från någon annan i Sverige
          </li>
        </ul>
      </div>
    );
  }
}
export default HardwareShop;

import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router'
import { Menu, Dropdown } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import OldRaspberry from './old/old-raspberry'
import NewRaspberry from './new/new-raspberry'
import imageSettingsStore from '../../Stores/image-settings-store'

class Raspberry extends Component {
  handleItemClick = (e, { name }) => {
    imageSettingsStore.setPictureSize(name)
  }

  render() {
    return (
      <div>
        <Menu stackable attached='top' compact text>
          <Menu.Item header>Raspberries</Menu.Item>
          <Menu.Item icon='arrow right' />
          <Dropdown item text={'Images : ' + imageSettingsStore.PictureSize}>
            <Dropdown.Menu>
              <Dropdown.Item text='Small' name='Small' onClick={this.handleItemClick} />
              <Dropdown.Item text='Medium' name='Medium' onClick={this.handleItemClick} />
              <Dropdown.Item text='Large' name='Large' onClick={this.handleItemClick} />
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item href='/monitor/rasp/new'> New images </Menu.Item>
          <Menu.Item href='/monitor/rasp/old'>Old view</Menu.Item>
        </Menu>
        <main>
          <Switch>
            <Route path='/monitor/rasp/new' component={NewRaspberry} />
            <Route path='/monitor/rasp/' component={OldRaspberry} />
          </Switch>
        </main>{' '}
      </div>
    )
  }
}
export default withRouter(observer(Raspberry))

import React, { Component } from 'react'
import imageSettingStore from '../../../Stores/image-settings-store'

class OldTemperature extends Component {
  renderall() {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Senaste sex timmarna</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Pellets-Tank.png')}

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Humidity-Livingroom.png')}
        <br />

        <h3>Senaste dygnet</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Pellets-Tank.png')}

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Humidity-Livingroom.png')}

        <br />
        <h3>Senaste två dygnen</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Pellets-Tank.png')}

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Humidity-Livingroom.png')}
        <br />
        <h3>Senaste veckan</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Pellets-Tank.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Humidity-Livingroom.png')}
        <br />
        <h3>Senaste månaden</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-Special.png')}

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Pellets-Tank.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Humidity-Livingroom.png')}

        <br />
        <h3>Senaste året</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Pellets-Tank.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Humidity-Livingroom.png')}
        <br />
        <h3>Allt uppmätt</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Pellets-Tank.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-AirPressure-Livingroom.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Humidity-Livingroom.png')}
      </div>
    )
  }
  rendersome() {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Senaste sex timmarna</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Pellets-Tank.png')}

        <br />

        <h3>Senaste dygnet</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Pellets-Tank.png')}

        <br />
        <h3>Senaste två dygnen</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2days-Pellets-Tank.png')}

        <br />
        <h3>Senaste veckan</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Pellets-Tank.png')}
        <br />
        <h3>Senaste månaden</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-Special.png')}

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Pellets-Tank.png')}

        <br />
        <h3>Senaste året</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Pellets-Tank.png')}
        <br />
        <h3>Allt uppmätt</h3>

        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-Special.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-InOutDoor.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Temperature-OutdoorSMHI.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-El-Energy.png')}
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/2years-Pellets-Tank.png')}
      </div>
    )
  }

  render() {
    if (imageSettingStore.ShowAllImages) {
      return this.renderall()
    } else {
      return this.rendersome()
    }
  }
}
export default OldTemperature

import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Menu } from 'semantic-ui-react'
import OwnAndroid from './own-android'
import OwnWindows from './own-windows'
import OwnGeneral from './own-general'

class ComputersOwnPrograms extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '5px' }}>
        <Menu secondary attach='top'>
          <Menu.Item href='/computers/ownprograms/general'>Egna Program, Allmänt</Menu.Item>
          <Menu.Item href='/computers/ownprograms/windows'>Windows</Menu.Item>
          <Menu.Item href='/computers/ownprograms/android'>Android</Menu.Item>
        </Menu>
        <Switch>
          <Route exact path='/computers/ownprograms/android' component={OwnAndroid} />
          <Route exact path='/computers/ownprograms/windows' component={OwnWindows} />
          <Route component={OwnGeneral} />
        </Switch>
      </div>
    )
  }
}
export default ComputersOwnPrograms

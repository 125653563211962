import React, { Component } from "react";
import minifront from "../../../Images/mini-front.jpg";
import miniback from "../../../Images/mini-back.jpg";
import { Image, Container, Grid } from "semantic-ui-react";

class CarsMiniCooper extends Component {
  render() {
    return (
      <Container height="90%">
        <h2>Detta är min bils historia.</h2>
        <div className="clearfix" style={{ padding: "15px" }}>
          <p>
            Jag köpte bilen när jag var 17 år dvs 1980 för 1300 kr !!
            <br />
            Lagade upp all rost och körde med den under gymnasiet och lumpen. sen ville jag inte
            köra med den under vintern och skaffade andra bilar dock har jag aldrig tänkt tanken att
            sälja den !!
          </p>
        </div>
        <Grid columns={2} padded>
          <Grid.Column>
            <Image src={minifront} width="400px" alt="Generic placeholder image" />
          </Grid.Column>
          <Grid.Column>
            <Image src={miniback} width="400px" alt="Generic placeholder image" />
          </Grid.Column>
        </Grid>
        <div>
          <h4>Bilens historia:</h4>
          <ul>
            <li>
              1980-1981: Rost lagat. Nya skärmar, front och bakre subframe. A och C-stolpar
              deseamade. Batterilåda omgjord med tät "båtlåda" i plast, ingen rost där mer :-)
              Interiör omgjord med platt instrumentpanel och instrument från MG 1300 mkII Elsystem
              helt omdraget med 10 st säkringar. Trumbromsar :-(
            </li>

            <li>
              1981 Cooper original-skivor monterade - blir användsjuk på mopeder - vilka rejäla
              bromsskivor dom har
            </li>
            <li>
              1982 Färddator med cruisecontrol monterad. Allegro 1300 motor isläppt. Oljekylare
              monterad. Efter att fått anmärkning på svaga bromsar bak för tredje året, slänger jag
              in en mutter i regulatorn. Ingen anmärkning - trots att besikningsmannen LÅSER
              bakhjulen på bromsprovaren ! Nåväl - en hundkoja skall inte vara för lätt att köra -
              men fy vad roligt man kan ha när man lärt sig vänsterbromsa !!
            </li>
            <li>
              1983 Efter att ha vänsterbromsat mej genom hela stan i vinterns första snöfall
              tvärnitar jag i en vattenpöl utanför en korvkiosk - Smällen som hördes var när ena
              bromsskivan sprack och den andra exploderade i småbitar. Trumbromsar igen !
            </li>
            <li>
              1985: 1275GT skivor monterade, köpta, kompletta med nav, för 500 kr. Skärmbreddare
              monterade. Kaross-sidor helt deseamade. Baklucka modifierad, gångjärnen invändigt ,
              wirelås , Nummerplåt "invinklad" ,belysning försänkt i luckan. Bakre kofångarfäste
              bortplockat. Omlackering. Ljudisolering !!
            </li>
            <li>
              1988 Rost i franskärmarna igen - Ingen tid att meka ! Bilen avställd (morbrors lagård)
            </li>
            <li>
              1994 Krockar min andra bil- Behöver nåt att åka i. Utrustad med ett batteri och
              verktygslåda åker jag ut till bilen.
              <br /> I med batteriet, två hammarslag på bensinpumpen - lite startgas och sen
              startade motorn. Tyvärr hade hävarmen till kopplingen ärgat fast, loss med kåpan och
              slå lite med en slägga. Sedan körde jag hem bilen, bromsar och allt annat verkade ok,
              Så jag städade och tvättade av bilen och körde till bilprovningen - fick en etta för
              att handbromsen tog ojämnt :-) Besiktningsmannen sa att "bilen klarat vintern bra" ,
              när jag sa att "den klarat FEM vintrar bra" försvann han in under bilen och kollade
              allt en gång till !
            </li>
          </ul>
        </div>
        <div>
          <h4>Dessutom har under åren diverse roligt inträffat:</h4>
          <p>
            1984: bråttom till Mike Oldfield koncert i Göteborg. Nerför sista backen till Göteborg
            skär motorn ( i ca 170 km/h ) vilket oljud !
          </p>
          <p>
            1985: jag och några kompisar har bråttom till jobbet. Full gas ! Körde visst om någon
            annan från jobbet. Efteråt blev vi tillfrågade hur fort det gått när vi körde om. <br />
            - M ! <br />- Vadå M ? <br />- Jag vet inte riktigt hur fort det gick, hastighetsmätaren
            slutar vid 160 - och vi var vid "m" i "km/h"{" "}
          </p>
          <p>
            1986: Efter att vi varit ute och festat några stycken, får vi för oss att eftersom jag
            var nykter så kunde ju jag köra till en efterfest, nåväl - när vi klämt in 8 pers i
            hundkojan kläcker någon ur sig "Kommer polisen får fyra ducka "
          </p>
          <p>
            1994: en kompis köper en hundkoja ( Mini 1000 ). Vi tog en provtur i min koja - i 160
            km/h på motorvägen säger han plötsligt "Min låter mer i 90 km/h"
            <br />
            <br />
            &nbsp;
          </p>
        </div>
      </Container>
    );
  }
}
export default CarsMiniCooper;

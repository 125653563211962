import React, { Component } from "react";
import { List } from "semantic-ui-react";

class GenericPrograms extends Component {
  render() {
    return (
      <List.Item>
        <List.Icon name="wizard" />
        <List.Content>
          <List.Header>Generic Tools</List.Header>
          <List.Description>
            <List.List>
              <List.Item>
                <List.Icon name="terminal" />
                <List.Content>
                  <List.Header>Cmder</List.Header>
                  <List.Description>
                    Cmd on steroids - tabs, unix syntax
                    <br /> <a href="https://cmder.net/">Ladda ner</a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="bug" />
                <List.Content>
                  <List.Header>Sysinternals</List.Header>
                  <List.Description>
                    Verktyg för att kolla under huven, från Microsoft
                    <br /> <a href="https://docs.microsoft.com/sysinternals">sysinternals site</a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="battery three quarters" />
                <List.Content>
                  <List.Header>BatteryBar </List.Header>
                  <List.Description>
                    Bättre batterimätare - även fria versionen är superbra
                    <br /> <a href="http://batterybarpro.com/basic.php">Ladda ner</a>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="edit" />
                <List.Content>
                  <List.Header>Notepad++</List.Header>
                  <List.Description>
                    Det finns många små text editorer som är bättre än det som Microsoft skickar med
                    Windows <br />
                    Jag föredrar Notepad++ , men <a href="https://www.sublimetext.com/">
                      Sublime
                    </a>{" "}
                    är också ok
                    <br /> bästa med Notepad++ är att det finns en massa plugins för olika format
                    och att man kan editera kolumnvis
                    <br />
                    <a href="https://notepad-plus-plus.org/downloads/">Ladda ner Notepad++</a>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List.List>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
}
export default GenericPrograms;

import React, { Component } from 'react'
import { Menu, Image, Dropdown } from 'semantic-ui-react'
import img1 from '../Images/skriv.gif'

// https://reactstrap.github.io/components

class MainMenu extends Component {
  render () {
    return (
      <Menu stackable compact attaced='top' text>
        <Menu.Item href='/'>
          <Image src={img1} width='25px' />
          Hem
        </Menu.Item>
        <Dropdown item text='Bilar / MC'>
          <Dropdown.Menu>
            <Dropdown.Item href='/cars/'>Allmänt</Dropdown.Item>
            <Dropdown.Item href='/cars/mc'>MC</Dropdown.Item>
            <Dropdown.Item href='/cars/saab'>SAAB</Dropdown.Item>
            <Dropdown.Item href='/cars/minicooper'>Mini Cooper -66</Dropdown.Item>
            <Dropdown.Item href='/cars/sayings'>Ordlista för bilmekaniker</Dropdown.Item>
            <Dropdown.Item href='/cars/volvo'>Volvo</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown item text='Datorer'>
          <Dropdown.Menu>
            <Dropdown.Item href='/computers/'>Allmänt</Dropdown.Item>
            <Dropdown.Item href='/computers/ownprograms'>Egna Program</Dropdown.Item>
            <Dropdown.Item href='/computers/goodprograms'>Bra Program</Dropdown.Item>
            <Dropdown.Item href='/computers/hardware'>Hårdvara</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown item text='IOT'>
          <Dropdown.Menu>
            <Dropdown.Item icon='thermometer' href='/monitor/temp' text='Temperaturer' />
            <Dropdown.Item icon='microchip' href='/monitor/rasp' text='Raspberries' />
            <Dropdown.Item icon='stethoscope' href='/monitor/net' text='Nätverk' />
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown item text='Hjälp'>
          <Dropdown.Menu>
            <Dropdown.Item icon='info' direction='right' href='/general/about/' text='Om...' />
            <Dropdown.Item icon='history' direction='right' href='/general/changes/' text='Ändringar' />
            {/*
            <Dropdown.Item divider='true' />
            <Dropdown.Item icon='globe' direction='right' href='/general/' text='Övrigt' />
 */}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    )
  }
}
export default MainMenu

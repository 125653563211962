import React from 'react'
import { observable, action, decorate } from 'mobx'
import Storage from '../Util/storage'

class ImageSettingsStore {
  PictureSize
  ShowAllImages
  loadSavedPictureSize() {
    if (Storage.storageAvailable('localStorage')) {
      if (localStorage.getItem('PictureSize')) {
        this.setPictureSize(localStorage.getItem('PictureSize'))
      } else {
        this.setPictureSize('Medium')
      }
      if (localStorage.getItem('ShowAllImages')) {
        this.setShowAllImages(localStorage.getItem('ShowAllImages'))
      } else {
        this.setShowAllImages(false)
      }
      return
    }
    this.setShowAllImages(false)
    this.setPictureSize('Medium')
  }

  setShowAllImages(showall) {
    if (showall === 'false') {
      showall = false
    }
    this.ShowAllImages = showall
    localStorage.setItem('ShowAllImages', this.ShowAllImages)
  }

  setPictureSize(newsize) {
    this.PictureSize = newsize
    localStorage.setItem('PictureSize', this.PictureSize)
  }

  getimage(href) {
    if (this.PictureSize === 'Small') {
      return (
        <a href={href}>
          <img width='200' height='150' alt='' src={href} />
        </a>
      )
    }
    if (this.PictureSize === 'Large') {
      return (
        <a href={href}>
          <img width='600' height='450' alt='' src={href} />
        </a>
      )
    }
    // defaults to medium
    return (
      <a href={href}>
        <img width='400' height='300' alt='' src={href} />
      </a>
    )
  }
}
decorate(ImageSettingsStore, {
  PictureSize: observable,
  ShowAllImages: observable,
  setPictureSize: action,
  setShowAllImages: action,
  loadSavedPictureSize: action
})
export default new ImageSettingsStore()

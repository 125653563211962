import React, { Component } from 'react'

class MainView extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h2>Välkommen till DenSomLever.se</h2>
        <h4>Min alldeles egen lilla hemsida mitt i hypervärlden</h4>
        Jag har börjat om igen på en ny sida
        <br /> - den gamla var gjord i ett verktyg (NetObjects Fusion) som i och för sig är både gratis och bra men dom
        vägrar göra uppgraderingar bakåt kompatibla och försöker desperat få mig att köpa deras kommersiella produkt
        istället.
        <br />
        Så eftersom jag numera ägnar en hel del tid på jobbet åt sånt där "Web" och sånt så jag får väl göra en egen
        sida
        <br />
        Du hittar lite om mina intressen i menyn ovan
        <br />
        <br /> <br />
        <li> Själv är bästa dräng ! </li>
        <li>If the only tool you have is a hammer, you tend to see every problem as a nail. </li>
        <li>I don't know where I'm going, but I'm on my way. </li>
        <li>Birthdays are good for you ! -the more you have, the longer you live</li>
        <br /> <br /> <br />
        {/*        <Nav vertical>
          <NavLink href="/general/about/">Om...</NavLink>
          <NavLink href="/general/changes/">Ändringar</NavLink>
        </Nav>
*/}
      </div>
    )
  }
}
export default MainView

import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'

class OwnGeneral extends Component {
  render () {
    return (
      <Container height='90%'>
        <div className='clearfix' style={{ padding: '15px' }}>
          <h3>Egna program</h3>
          <p>
            Programmering har jag hållt på med både professionellt och som hobby sedan mitten på 80-talet eller så -
            allt ifrån först generationen av IBM-PC :-)
          </p>
          <br />
          <p>just nu håller jag mest på med :</p>
          <ul>
            <li>C#</li>
            <li>java</li>
            <li>reactjs (Web)</li>
            <li>perl (raspberry pi)</li>
            <li>python (raspberry pi)</li>
          </ul>
          <br />
          <p>Tidigare :</p>
          <ul>
            <li>Turbo Pascal</li>
            <li>Delphi</li>
            <li>LabView</li>
            <li>C </li>
            <li>C++ </li>
          </ul>
          <p>
            Jag har alltid tyckt att bra små program bör vara gratis - lite "reklam för programmeraren" <br />
            Alltså delar jag själv med mig av det jag gjort.
          </p>
        </div>
      </Container>
    )
  }
}
export default OwnGeneral

import React, { Component } from 'react'

class Changes extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        Changes
        <ul>
          <li>
            <p>2018-12-11</p>
            lagt om så att default är dom gamla vyerna med bilder på IOT
          </li>
          <li>
            <p>2018-07-12</p>
            slängt ut första versionen av sidan i ny tappning <br />- leker lite react när man ändå håller på
          </li>
        </ul>
      </div>
    )
  }
}
export default Changes

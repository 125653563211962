import React, { Component } from "react";

class CarsSayings extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        <h2>Ordlista för bilmekaniker </h2>
        <ul>
          <li>
            Avbitartång<br />En kraftig nagelsax som alltid klipper av elkabeln istället för att
            skala den Kan orsaka tryckmärken i hårda metallföremål, men detta är en ganska ovanlig
            händelse.
          </li>
          <li>
            Borr<br />Finns endast i fyra varianter. För stora, för små, avbrutna eller slitna. De
            för små är oftast de som är avbrutna. De slitna är vanligtvis de som har rätt diameter.
          </li>
          <li>
            Borrmaskin<br />Elektrisk hand- Används Normalt för att rotera popnitar i sina hål, men
            kan också användas för att borra fästhål till störtbågar i golv (speciellt ovanför
            bromsrör och bränsletankar).
          </li>
          <li>
            Bågfil<br />En i en familj av skärande verktyg som arbetar enligt slumpprincipen. Den
            konverterar hårt arbete till slumpmässiga och okontrollerade rörelser.
          </li>
          <li>
            Domkraft<br />Hydraulisk garage- Används för att höja/sänka fordon, finns i två
            utföranden, en för låg och en för hög.
          </li>
          <li>
            Elektrolyttestare<br />Ett användbart verktyg för att överföra batterisyra från
            bilbatterier till verktygslådan. Speciellt när batteriet var stendött som man redan
            misstänkte.
          </li>
          <li>
            El-tejp<br />Vanligtvis en svart plastremsa, ca 15 mm bred, som ska vara klistrig på en
            sida. Ursprungligen avsett att isolera elkablar med. Används oftast till att fästa
            slangar, kabelstammar, batteri, stolar o.dyl. med, samt tillsammans med en bit oljigt
            torkpapper som plåster, sk &quot;knegarplåster&quot;.
          </li>
          <li>
            Furuplank<br />2&quot;x4&quot;: Används för att justera lyfthöjden på ovan nämnda
            domkraft.
          </li>
          <li>
            Fälgkors<br />Ursprungligen anordning för att lossa hjulmuttrar. Används mest till att
            runda av nämnda muttrar så att man inte kan demontera hjulet.
          </li>
          <li>
            Gas-svets<br />Anv. normalt för att tända gamla cigaretter som har legat längst ner i
            verktygslådan ihop med tumhylsorna. Dessa cigaretter har ofta en svag smak av
            batterisyra från elektrolyttestaren.
          </li>
          <li>
            Hammare<br />Vapen, numera använt som trollstav för att finna de dyraste bildelarna i
            närheten av det man försöker träffa.
          </li>
          <li>
            Handlampa<br />Mekanikerns enda kända källa till D-vitaminer, dessa återfinns normalt
            inte under bilar mitt i natten. I övrigt används den till att förbruka 40W-lampor i
            samma takt som haubitsgranater inför Desert Storm. Namnet missvisande eftersom den
            oftare är mer svart än lysande.
          </li>
          <li>
            Högtryckstvätt<br />Anordning för tvätt av smutsiga detaljer med hjälp av vatten under
            högt tryck. Dock träffar strålen allt annat än den ska. Vanligtvis byxbenen, ansiktet
            och all känslig elektronik i bilen samt hela tändsystemet, så att bilen aldrig mer går
            att starta.<br />Ång-: Hetvatten-: En tvätt försedd med en liten oljepanna som värmer
            upp vattnet. Gör att det blir trevligare att bli träffad av tidigare nämnda
            vattenstråle. Även effektiv att tvätta bort underredsmassa och billack med.
          </li>
          <li>
            Kaffebryggare<br />Viktigaste näringskällan näst efter den kalla pizzan. Ofta enda
            värmekällan i kallgarage på vintern. I värsta fall doppas händerna i det varma kaffet
            för att tina fingrarna så att skiftnyckeln kan lossna.
          </li>
          <li>
            Liggbräda<br />Kort och obekväm plattform utrustad med 4 hjul. Tänkt att användas
            liggandes på under ett fordon. Men då denna tingest sällan går att styra dit man vill
            pga. verktyg eller sten på golvet, brukar det oftast sluta med ett hysteriskt utbrott.
            Enda gången detta redskap rullar lätt är när man trampar på den. Kan även fungera till
            korta tupplurar. Kallas även garagehora eller bara hora.
          </li>
          <li>
            Mig-svets<br />Elektrisk anordning för att tända eld på underredsmassa, bensinledningar
            och liknande samt sprätta vitglödande järnkulor innanför tröjor, kalsonger, byxor, skor
            och dylikt.
          </li>
          <li>
            Morakniv<br />Ursprungligen för att tälja dalahästar med, används numera till att:<br />1.
            Öppna kartonger med suffletter eller andra klädselöverdrag.<br />2. Skrapa rost från
            bildelar.
          </li>
          <li>
            Motorlyft<br />Hydraulisk 2 ton: Verktyg för att testa draghållfastheten i jordflätor
            och växelspaks-länkage.
          </li>
          <li>
            Pelarborrmaskin<br />En hög upprättstående maskin som sliter metallstycken ur dina
            händer och kastar den mot din bröstkorg eller dyrbara elektriska mätinstrument.
          </li>
          <li>
            Pincett<br />Används för att ta ut trästickor i fingrar från tidigare nämnda furuplank,
            2&quot;x4&quot;
          </li>
          <li>
            Rörtång<br />Används för att runda av bult/mutterskallar. Även till att dra av dylika.
          </li>
          <li>
            Skiftnyckel<br />Ytterligare ett verktyg i kategorin &quot;muttersvarvar&quot;, som
            används för att svarva av bultar o. muttrar. Samt eventuellt som hammare, flasköppnare,
            skjutmått m.m
          </li>
          <li>
            Skrapa, packnings-:<br />Kan användas för att breda ut mjukost på limpmackor men oftast
            för att skrapa bort hundbajs från dina mekarskor
          </li>
          <li>
            Skruvmejsel vanlig, 300 mm Bahco<br />Stort bryt- och skrapverktyg som av någon
            anledning har ett fint bearbetad spets i motsatt ände som handtaget (i bästa fall).
          </li>
          <li>
            Skruvmejsel Phillips<br />Används normalt till att öppna färgburkar så att färgen
            skvätter på t-shirten, men kan även användas (som namnet antyder) för att runda av
            skallen på Phillipskruvar, för att styra upp växellådan mot motorn eller som dorn.
          </li>
          <li>
            Skruvutdragare<br />Verktyg som går av i skruvhål och som har en hårdhet som överstiger
            en HSS-borr med en faktor 10.
          </li>
          <li>
            Slagskruvmejsel<br />Snillrik anordning för att överföra ett slag till en vridande
            rörelse på en skruv som sitter fast. Även ytterligare en anledning till att slå sig
            själv på handen med en hammare när man missar nämnda verktyg.
          </li>
          <li>
            Stålborste roterande<br />Används för att slipa bort rost från gamla skruvar och
            fingeravtryck från fingrar.
          </li>
          <li>
            Svetstång (se även Tång)<br />Används normalt till att överföra svetsvärme till
            handflator.
          </li>
          <li>
            Telefon<br />Används för att ringa efter polaren som har en annan garagedomkraft i rätt
            höjd. Alternativt beställa föda från den lokala korv- eller pizzamånglaren.
          </li>
          <li>
            Tryckluftkompressor<br />En maskin som omvandlar elektrisk, energi skapad i ett
            kärnkraftverk ett 50-tal mil bort, till komprimerad luft, som via en slang, driver en
            mutterdragare, som rundar av skallar på rostiga skruvar som senast drogs åt av någon i
            Japan för 15 år sedan. Nämnda tryckluft kan även användas för att blåsa grus och
            järnspån upp i ögonen, eller göra lustiga ljud med hjälp av ett blåsmunstycke.
          </li>
          <li>
            Tumhylsor<br />Tidigare använda på gamla engelska eller amerikanska fordon, numera för
            att gömma gamla paket med gula Blend. Kan även användas för att lossa tidigare
            runddragna skruvskallar, hylsan monteras då enklast med hjälp av en hammare.
          </li>
          <li>
            Tång motor-<br />Tortyrredskap för att klämma av skinnet i handflatan när tången slinter
            på det man försöker hålla.
          </li>
          <li>
            Tändinställningslampa<br />En stroboskopisk lampa som anv. för att betrakta gravt
            nersmutsade remskivor eller nedoljade svänghjul (eller leka Star Wars).
          </li>
          <li>
            Vinkelslip<br />En, vanligtvis, elektrisk anordning för att kapa av elkabelstammar,
            fingrar samt bränslerör (och antända bensinen i röret). Även användbar till att antända
            overaller, det oljiga papperet på golvet, samt fylla samtliga glasrutor med små
            metallprickar.
          </li>
        </ul>
      </div>
    );
  }
}
export default CarsSayings;

import React, { Component } from "react";

class SportsGolf extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        SportsGolf
      </div>
    );
  }
}
export default SportsGolf;

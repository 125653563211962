import React, { Component } from 'react'

class CarsVolvo extends Component {
  render () {
    return (
      <div className='clearfix' style={{ padding: '15px' }}>
        <h3>Volvo</h3>
        <text>
          <br />
          <p>
            jag har inte kvar någon Volvo längre, det ligger bara kvar några delar som jag gärna avyttrar{' '}
          </p>
          <h4>TILL SALU</h4><br />
          <ll>
            <li>
              Har fortfarande ett rostfritt avgasrör till 940 LTT / 16V som jag plockade av min bil innan jag skrotade den
              500:- och det är ditt :-){' '}
            </li>
            <li>Har även lastgaller med hållare och mattor(en sprucken) till bagagerummet på 945 - 300:- </li>
            <li>Instrumentpaneler - har några - t.o.m en med turbomätare - köpte men monterade aldrig den. </li>
            <li>blandade relän, två uppsättningar med ALLA relän under askkoppen</li>
            <li>vacuumpump till farthållare</li>
            <li>finns nog en tändmodul också  ny (från Biltema)</li>
          </ll>
        </text>

      </div>
    )
  }
}
export default CarsVolvo

import React, { Component } from "react";

class ComputersGeneral extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        <p>
          Programmering och datorer har jag hållt på med både professionellt och som hobby sedan
          mitten på 80-talet eller så - allt ifrån först generationen av IBM-PC :-)
        </p>
      </div>
    );
  }
}
export default ComputersGeneral;

import React, { Component } from "react";

class CarsGeneral extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        <p>
          Bilar - har jag hållit på med så länge jag minns - nästan !<br />
          men det är klart, om ens farsa har en bilverkstad så är det lätt att man “växer upp” där -
          ett och annat tips har man väl plockat upp.
        </p>
        <p>
          Med tiden så har jag ägnat mig åt olika bilmärken och deras förtjänster och brister,<br />
          -just nu (2018) så är det Saab som gäller - 9-5 kombi - har en 2001:a och en 2002:a<br />
          mer om detta på <a href="/cars/saab/">Saab-Sidan</a>
        </p>
        <p>
          Tidigare har jag ägnat mig åt gamla Volvo och Hundkojor.<br />
          Mina misstag och kommentarer om volvo 855 och 945 finns dokumenterade på{" "}
          <a
            href="https://www.jagrullar.se/forum/search.php?keywords=&terms=all&author=emwtur"
            target="_new"
          >
            Sveriges Volvoforum
          </a>
        </p>
        <p>
          Historien om min Hundkoja skrev jag en gång för länge sedan och lät publicera på en av
          MiniClub Sweden’s eldsjälars hemsida.<br />
          Historien finns även <a href="/cars/minicooper/">här</a> nu
        </p>
      </div>
    );
  }
}
export default CarsGeneral;

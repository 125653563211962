import React, { Component } from 'react'
import imageSettingStore from '../../../Stores/image-settings-store'

class OldNetwork extends Component {
  render() {
    return (
      <div className='clearfix' style={{ padding: '15px', overflow: 'auto' }}>
        <h3>Senaste sex timmarna</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/hour-Packet-Loss-Ping.png')}
        <h3>Senaste dygnet</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/day-Packet-Loss-Ping.png')}
        <br />
        <h3>Senaste veckan</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/week-Packet-Loss-Ping.png')}
        <br />
        <h3>Senaste månaden</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/month-Packet-Loss-Ping.png')}
        <br />
        <h3>Senaste året</h3>
        {imageSettingStore.getimage('https://www.densomlever.se/assets/images/termo/year-Packet-Loss-Ping.png')}
      </div>
    )
  }
}

export default OldNetwork

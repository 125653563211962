import React, { Component } from "react";

class SportsVolley extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        SportsVolley
      </div>
    );
  }
}
export default SportsVolley;

import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router'
import { Menu, Dropdown, Checkbox } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import NewTemperature from './new/new-temperature'
import OldTemperature from './old/old-temperature'
import imageSettingsStore from '../../Stores/image-settings-store'

class Temperature extends Component {
  handleSizeClick = (e, { name }) => {
    imageSettingsStore.setPictureSize(name)
  }

  handleAllClick = (e, { checked }) => {
    imageSettingsStore.setShowAllImages(checked)
  }

  render() {
    return (
      <div>
        <Menu stackable attached='top' compact text>
          <Menu.Item header>Temperaturer -></Menu.Item>
          <Dropdown item text={'Images : ' + imageSettingsStore.PictureSize}>
            <Dropdown.Menu>
              <Dropdown.Item text='Small' name='Small' onClick={this.handleSizeClick} />
              <Dropdown.Item text='Medium' name='Medium' onClick={this.handleSizeClick} />
              <Dropdown.Item text='Large' name='Large' onClick={this.handleSizeClick} />
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item>
            <Checkbox label='Show all' onChange={this.handleAllClick} checked={imageSettingsStore.ShowAllImages} />{' '}
          </Menu.Item>
          <Menu.Item href='/monitor/temp/new' name='New images' />
          <Menu.Item href='/monitor/temp/old' name='Old view' />
        </Menu>
        <main>
          <Switch>
            <Route path='/monitor/temp/new' component={NewTemperature} />
            <Route component={OldTemperature} />
          </Switch>
        </main>
      </div>
    )
  }
}
export default withRouter(observer(Temperature))

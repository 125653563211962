import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router'
import MainMenu from './main-menu'
import MainView from './main-view'
import Temperature from './Monitor/temperature'
import Raspberry from './Monitor/raspberry'
import Changes from './General/changes'
import About from './General/about'
import CarsMain from './Cars/cars-main'
import GeneralMain from './General/general-main'
import ComputersMain from './Computers/computers-main'
import Network from './Monitor/network'
import SportsMain from './Sports/sports-main'
import SportsVolley from './Sports/sports-volley'
import SportsGolf from './Sports/sports-golf'
import { observer } from 'mobx-react'
import ImageSettingsStore from '../Stores/image-settings-store'

class App extends Component {
  componentDidMount() {
    ImageSettingsStore.loadSavedPictureSize();
  }

  render() {
    return (
      <div>
        <MainMenu />
        <Switch>
          <Route path='/computers/' component={ComputersMain} />
          <Route path='/cars/' component={CarsMain} />
          <Route exact path='/general/changes/' component={Changes} />
          <Route exact path='/general/about/' component={About} />
          <Route path='/general/' component={GeneralMain} />

          <Route path='/monitor/rasp/' component={Raspberry} />
          <Route path='/monitor/temp/' component={Temperature} />
          <Route path='/monitor/net/' component={Network} />

          <Route exact path='/sports/volley' component={SportsVolley} />
          <Route exact path='/sports/golf/' component={SportsGolf} />
          <Route path='/sports/' component={SportsMain} />

          <Route component={MainView} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(observer(App))

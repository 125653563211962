import { observable, action, decorate } from 'mobx'



class ProgramStore {
    programs = {};

    Loading = false;
    baseurl = 'https://www.densomlever.se/assets/Installers/';

    geturl(msipath) {
        return this.baseurl + msipath;
    }
    getprogramlist() {
        this.getprograminfo("TurboTray");
    }

    getprogram(prgname) {
        console.log("getprogram  " + prgname);
        var result = this.programs[prgname];
        return result;
    }

    setprogram(prgname, prgdata) {
        console.log("setprogram  " + prgname);
        this.programs[prgname] = prgdata;
        console.log(prgdata);
    }

    getprograminfo(prgname) {
        console.log("getprograminfo  " + prgname);
        let url = this.baseurl + prgname + '.json';
        this.Loading = true;
        fetch(url)
            .then((resp) => resp.json())
            .then((data) => {
                this.setprogram(prgname, data);
                this.Loading = false;
            })
            .catch(error => {
                console.error(error);
                this.Loading = false;
            });
    }
}

decorate(ProgramStore, {
    Loading: observable,
    baseurl: observable,
    programs: observable,
    getprogramlist: action,
    getprograminfo: action,
    setprogram: action
});
export default new ProgramStore();
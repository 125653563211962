import React, { Component } from "react";

class SportsMain extends Component {
  render() {
    return (
      <div className="clearfix" style={{ padding: "15px" }}>
        SportsMain
      </div>
    );
  }
}
export default SportsMain;
